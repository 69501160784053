<template>
  <transition name="bounce">
    <b-card title="Consultar avisos WS" v-if="can('/consulta-ws')">
      <b-row>
        <b-col md="4">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="form.num_aviso"
              placeholder="N° aviso"
            />
            <div class="input-group-append">
              <button
                class="btn btn-primary"
                type="button"
                @click="consultWS()"
                :disabled="loading"
              >
                {{ loading ? "Consultando... " : "Consultar!" }}
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="separator separator-dashed mt-3 mb-3" v-if="response"></div>
      <transition name="bounce">
        <b-row v-if="response">
          <b-col>
            <div class="alert bg-secondary">
              <p>Message: {{ response.message }}</p>
              <p v-for="(item, idx) in response.aviso" :key="idx">
                {{ idx }} : {{ item }} 
              </p>
            </div>
          </b-col>
        </b-row>
      </transition>
    </b-card>
    <b-card v-else>
      <div class="alert bg-secondary">No tiene permisos</div>
    </b-card>
  </transition>
</template>
<script>
import axios from "axios";
import can from "@/permission";
export default {
  data() {
    return {
      form: {
        num_aviso: null,
      },
      loading: false,
      response: null,
    };
  },
  methods: {
    can: can,
    consultWS() {
      if (this.form.num_aviso) {
        this.loading = true;
        axios
          .post(`/aviso-estado/${this.form.num_aviso}`)
          .then((res) => {
            this.response = res.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        this.$swal.fire({
          title: "Información",
          text: "Debe digitar el aviso de forma correcta",
          icon: "warning",
        });
      }
    },
  },
};
</script>
